import {Routes} from '@angular/router';
import {AutoLoginPartialRoutesGuard} from 'angular-auth-oidc-client';
import {translocoLoaderGuard} from '../../../alcedo/src/app/core/guards/transloco-loader.guard';
import {optionalLoginGuard} from './core/guards/optional-login.guard';
import {userLoaderGuard} from './core/guards/user-loader.guard';
import {AlertingAskComponent} from './iot/alerting/alerting-ask/alerting-ask.component';
import {AlertingChoiceComponent} from './iot/alerting/alerting-choice/alerting-choice.component';
import {AlertingCompleteComponent} from './iot/alerting/alerting-complete/alerting-complete.component';
import {AlertingConfigureComponent} from './iot/alerting/alerting-configure/alerting-configure.component';
import {AlertingCopyComponent} from './iot/alerting/alerting-copy/alerting-copy.component';
import {AlertingNoAlarmChainComponent} from './iot/alerting/alerting-no-alarm-chain/alerting-no-alarm-chain.component';
import {DeviceChangeNameComponent} from './iot/device-change-name/device-change-name.component';
import {IoTComponent} from './iot/iot.component';
import {NoAccountInfoComponent} from './iot/no-account-info/no-account-info.component';
import {ConfirmClientComponent} from './iot/sign-up/confirm-client/confirm-client.component';
import {LoginComponent} from './iot/sign-up/login/login.component';
import {DeviceRealEstateAddComponent} from './iot/sign-up/real-estate/device-real-estate-add/device-real-estate-add.component';
import {DeviceRealEstateAskComponent} from './iot/sign-up/real-estate/device-real-estate-ask/device-real-estate-ask.component';
import {DeviceRealEstateSelectComponent} from './iot/sign-up/real-estate/device-real-estate-select/device-real-estate-select.component';
import {ReplaceDeviceConfirmComponent} from './iot/sign-up/replace-device-confirm/replace-device-confirm.component';
import {ReplaceDeviceComponent} from './iot/sign-up/replace-device/replace-device.component';
import {SignUpAskComponent} from './iot/sign-up/sign-up-ask/sign-up-ask.component';
import {SignUpCompleteComponent} from './iot/sign-up/sign-up-complete/sign-up-complete.component';
import {SignUpConfirmActivationComponent} from './iot/sign-up/sign-up-confirm-activation/sign-up-confirm-activation.component';
import {SignUpConfirmComponent} from './iot/sign-up/sign-up-confirm/sign-up-confirm.component';
import {SignUpResendEmailComponent} from './iot/sign-up/sign-up-resend-email/sign-up-resend-email.component';

export const routes: Routes = [
  {
    path: 'iot',
    redirectTo: '',
    canMatch: [translocoLoaderGuard],
    pathMatch: 'full'
  },
  {
    path: '',
    component: IoTComponent,
    canActivate: [userLoaderGuard],
    canMatch: [translocoLoaderGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./iot/device-hub/device-hub.component').then(c => c.DeviceHubComponent),
        data: {animation: 'DeviceHub'},
        canActivate: [optionalLoginGuard]
      },
      {
        path: 'ar',
        loadComponent: () => import('./iot/ar/ar.component').then(c => c.ArComponent),
        canActivate: [optionalLoginGuard]
      },
      {
        path: 'quiz',
        loadComponent: () => import('./iot/quiz/quiz.component').then(c => c.QuizComponent),
        data: {animation: 'Quiz'},
        canActivate: [optionalLoginGuard]
      },
      {
        path: 'scan',
        loadComponent: () => import('./iot/qr-scanner/qr-scanner.component').then(c => c.QrScannerComponent),
        canActivate: [optionalLoginGuard]
      },
      {
        path: 'no-account-info',
        component: NoAccountInfoComponent
      },
      {
        path: 'device/:activationCode/info',
        loadComponent: () => import('./iot/device-hub/device-info/device-info.component').then(c => c.DeviceInfoComponent),
        data: {animation: 'DeviceInfo'},
        canActivate: [optionalLoginGuard]
      },
      {
        path: 'device/:activationCode/change-name',
        component: DeviceChangeNameComponent,
        data: {animation: 'Registration'},
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {
        path: 'device/:activationCode/alerting-choice',
        component: AlertingChoiceComponent,
        data: {animation: 'AlarmSetup'},
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {
        path: 'device/:activationCode/alerting-configure',
        component: AlertingConfigureComponent,
        data: {animation: 'AlarmSetup'},
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {
        path: 'device/:activationCode/alerting-copy',
        component: AlertingCopyComponent,
        data: {animation: 'AlarmSetup'},
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {
        path: 'device/:activationCode/alerting-complete',
        component: AlertingCompleteComponent,
        data: {animation: 'AlarmSetup'},
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {
        path: 'device/:activationCode/alerting-failure',
        component: AlertingNoAlarmChainComponent,
        data: {animation: 'AlarmSetup'},
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {
        path: 'device/:activationCode/dp/:dataPointId',
        loadComponent: () => import('./iot/device-hub/data-point-info/data-point-info.component').then(c => c.DataPointInfoComponent),
        data: {animation: 'DataPointInfo'},
        canActivate: [optionalLoginGuard]
      },
      {
        path: 'device/:activationCode/comfort',
        loadComponent: () => import('./iot/device-hub/comfort-info/comfort-info.component').then(c => c.ComfortInfoComponent),
        data: {animation: 'DataPointInfo'},
        canActivate: [optionalLoginGuard]
      },
      {
        path: 'device/:activationCode/features/:page',
        loadComponent: () => import('./iot/device-hub/feature-intro/feature-intro.component').then(c => c.FeatureIntroComponent),
        data: {animation: 'FeatureIntro'},
        canActivate: [optionalLoginGuard]
      },
      {
        path: 'activate/confirmation',
        component: SignUpConfirmActivationComponent,
        data: {animation: 'Registration'},
        canActivate: [optionalLoginGuard]
      },
      {
        path: 'activate/confirmation/:clientActivationCode',
        component: SignUpConfirmActivationComponent,
        data: {animation: 'Registration'},
        canActivate: [optionalLoginGuard]
      },
      {
        path: 'activate/:activationCode/login',
        component: LoginComponent,
        data: {animation: 'Registration'},
        canActivate: [optionalLoginGuard]
      },
      {
        path: 'activate/:activationCode/sign-up',
        component: SignUpAskComponent,
        data: {animation: 'Registration'},
        canActivate: [optionalLoginGuard]
      },
      {
        path: 'activate/:activationCode/confirmation',
        component: SignUpConfirmComponent,
        data: {animation: 'Registration'},
        canActivate: [optionalLoginGuard]
      },
      {
        path: 'activate/:activationCode/resend-email',
        component: SignUpResendEmailComponent,
        data: {animation: 'Registration'},
        canActivate: [optionalLoginGuard]
      },
      {
        path: 'activate/:activationCode/confirmClient',
        component: ConfirmClientComponent,
        data: {animation: 'Registration'},
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {
        path: 'activate/:activationCode/client/:clientId/replace',
        component: ReplaceDeviceComponent,
        data: {animation: 'Registration'},
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {
        path: 'activate/:activationCode/client/:clientId/replace/:locationId/confirm',
        component: ReplaceDeviceConfirmComponent,
        data: {animation: 'Registration'},
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {
        path: 'activate/:activationCode/complete',
        component: SignUpCompleteComponent,
        data: {animation: 'Registration'},
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {
        path: 'activate/:activationCode/change-name',
        component: DeviceChangeNameComponent,
        data: {animation: 'Registration'},
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {
        path: 'activate/:activationCode/real-estate-ask',
        component: DeviceRealEstateAskComponent,
        data: {animation: 'Registration'},
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {
        path: 'activate/:activationCode/real-estate-add',
        component: DeviceRealEstateAddComponent,
        data: {animation: 'Registration'},
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {
        path: 'activate/:activationCode/real-estate-select',
        component: DeviceRealEstateSelectComponent,
        data: {animation: 'Registration'},
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {
        path: 'activate/:activationCode/alerting-ask',
        component: AlertingAskComponent,
        data: {animation: 'Registration'},
        canActivate: [AutoLoginPartialRoutesGuard]
      }
    ]
  },
  {
    // Compatibility route for existing QR codes
    path: 'device/:activationCode',
    canActivate: [userLoaderGuard, optionalLoginGuard],
    canMatch: [translocoLoaderGuard],
    component: IoTComponent
  },
  {path: '**', canMatch: [translocoLoaderGuard], redirectTo: ''}
];
