import {TranslocoService} from '@jsverse/transloco';
import {UserLocaleService} from '../../core/auth/user-locale.service';
import {Alcedo7User} from '../entities/user/avelon-user.service';

export const HELP_URLS = {
  baseUrl: '/docs/{language}/',
  pages: {
    accessToDataPointFilters: 'user/user-groups.html#access-to-data-point-filters',
    accessToDevices: 'user/user-groups.html#access-to-devices',
    activation: 'activation',
    addInterpolatedValues: 'data-point/record/add-interpolated-values.html',
    addresses: 'user/addresses.html',
    alerting: 'device/beetle/alertings.html',
    alertingOptions: 'device/beetle/alertings.html#settings',
    analogAndGenericProperties: 'network-object/bacnet/quality-check.html#analog-and-generic-properties',
    assignedTrendLogs: 'network-object/bacnet/quality-check.html#assigned-trend-logs',
    authenticationSettings: 'setting/users.html#authentication-settings',
    backupConfiguration: 'setting/system-settings.html#backup',
    categories: 'dashboard/dashboard-categories.html#manage-categories',
    copyMeasurements: 'data-point/record/copy-measurements.html',
    creationSets: 'dashboard/manage-creation-sets.html',
    dashboardsCreate: 'dashboard/create-dashboards.html',
    dashboardsDownload: 'report/office-add-in/insert-dashboard.html',
    dataPointGroups: 'data-point/calculated/data-point-groups.html',
    dataPointProfile: 'data-point/edit.html#data-point-profiles',
    dataPointTags: 'data-point/manage.html#data-point-tags',
    deleteInactiveUsers: 'setting/users.html#delete-inactive-users',
    deviceAccess: 'device/beetle/configure.html#device-access',
    importSchedule: 'data-point/data-exchange/import.html#schedule',
    intrinsicReporting: 'network-object/bacnet/quality-check.html#intrinsic-reporting',
    keyboardShortcuts: 'appendix/keyboard-shortcuts.html',
    localization: 'appendix/localization.html',
    modbusLinks: 'data-point/modbus-links.html',
    notifications: 'navigation/notifications.html',
    passwordExpirationPolicy: 'setting/users.html#password-expiration-policy',
    realEstateDetails: 'real-estate/details.html',
    reportJobs: 'report/report-jobs.html',
    ticketEdit: 'ticket-list/ticket-details.html#add-or-edit-a-ticket',
    ticketlistDetails: 'ticket-list/ticket-details.html',
    timeMasks: 'chart/time-mask.html',
    widgetsAdd: 'dashboard/widgets.html',
    widgetsDownload: 'report/office-add-in/insert-widget.html'
  }
};

export class HelpService {
  static translate: TranslocoService;

  static openHelp = (page?: string): void => {
    const language = Alcedo7User.currentUser
      ? UserLocaleService.getLanguage(Alcedo7User.currentUser.language)
      : HelpService.translate.getActiveLang();
    const url = HELP_URLS.baseUrl.replace(/{language}/, language) + (page || '');
    window.open(url, '_blank', 'noreferrer');
  };
}
