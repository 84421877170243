import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {EMPTY, forkJoin, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {unsubscribe} from '../../../../../../alcedo/src/app/shared/decorators/unsubscribe.decorator';
import {AlertingService} from '../../../core/services/alerting.service';
import {IoTService} from '../../../core/services/iot.service';

@Component({
  selector: 'app-alerting-choice',
  templateUrl: './alerting-choice.component.html',
  styleUrls: ['./alerting-choice.component.scss'],
  standalone: true,
  imports: [MatButtonModule, RouterLink, TranslocoModule]
})
export class AlertingChoiceComponent implements OnInit, OnDestroy {
  private loading$: Subscription;
  private reload$: Subscription;

  constructor(
    private alertingService: AlertingService,
    private route: ActivatedRoute,
    private router: Router,
    private iotService: IoTService
  ) {}

  ngOnInit() {
    this.loadAlertings();
    this.reload$ = this.iotService.reloadDevices.subscribe(() => this.loadAlertings());
  }

  ngOnDestroy(): void {
    unsubscribe(this);
  }

  private loadAlertings(): void {
    this.loading$?.unsubscribe();
    this.loading$ = this.iotService
      .loadDevices()
      .pipe(
        switchMap(() => {
          const activationCode = this.route.snapshot.params.activationCode;
          const device = this.iotService.getDeviceByActivationCode(activationCode);
          if (!device) {
            return EMPTY;
          }

          const devices = this.alertingService.getCopyEligibleDevices(activationCode);
          const eligibleAlarmChains = this.alertingService.findAllByClientAndDevice(device.id, device.clientId);
          return forkJoin([devices, eligibleAlarmChains]);
        })
      )
      .subscribe(([devices, alarmChains]) => {
        if (!devices.length) {
          this.router.navigate(['../alerting-configure'], {relativeTo: this.route, replaceUrl: true});
        }
        const eligibleAlarmChains = alarmChains.filter(alarmChain => alarmChain.eligible);
        if (!eligibleAlarmChains.length) {
          this.router.navigate(['../alerting-failure'], {relativeTo: this.route, replaceUrl: true});
        }
      });
  }
}
