import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {ActivatedRoute, ChildrenOutletContexts, Router, RouterLink, RouterOutlet} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {CgBusyDirective} from 'angular-busy2';
import {NgxPermissionsService} from 'ngx-permissions';
import {Subscription} from 'rxjs/internal/Subscription';
import {switchMap} from 'rxjs/operators';
import {unsubscribe} from '../../../../alcedo/src/app/shared/decorators/unsubscribe.decorator';
import {UserSessionService} from '../../../../alcedo/src/app/shared/entities/auth/user-session.service';
import {Alcedo7User} from '../../../../alcedo/src/app/shared/entities/user/avelon-user.service';
import {environment} from '../../environments/environment';
import {slideInAnimation} from '../core/animation';
import {AuthService} from '../core/auth.service';
import {BrandService} from '../core/brand.service';
import {DOMAINS} from '../core/domains.enum';
import {IoTService} from '../core/services/iot.service';
import {SystemService} from '../core/system.service';

@Component({
  selector: 'app-iot',
  templateUrl: './iot.component.html',
  styleUrls: ['./iot.component.scss'],
  animations: [slideInAnimation],
  standalone: true,
  imports: [
    CgBusyDirective,
    FormsModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,
    RouterLink,
    RouterOutlet,
    TranslocoModule
  ]
})
export class IoTComponent implements OnInit, OnDestroy {
  alcedoURL: string;
  Alcedo7User = Alcedo7User;
  selectedClientId: number;
  version = environment.version;
  title: string;
  isWagoDomain = location.hostname.includes('wago');
  private routeSub$: Subscription;
  private register$: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private brandService: BrandService,
    private systemService: SystemService,
    private permissions: NgxPermissionsService,
    private iotService: IoTService,
    private contexts: ChildrenOutletContexts,
    private oidcSecurityService: OidcSecurityService
  ) {
    this.selectedClientId = UserSessionService.getSelectedClientId();
    this.brandService.getTitle().subscribe((title: string) => (this.title = title));
  }

  ngOnInit() {
    this.fetchURLs();
    if (location.hostname === DOMAINS.OLD) {
      if (this.activatedRoute.snapshot.params.activationCode) {
        location.href = 'https://' + DOMAINS.NEW + '/device/' + this.activatedRoute.snapshot.params.activationCode;
      } else {
        this.router.navigate(['/']);
      }
    }
    this.routeSub$ = this.activatedRoute.params.subscribe(params => {
      if (params.hasOwnProperty('activationCode')) {
        this.register$?.unsubscribe();
        this.register$ = this.iotService
          .loadDevices()
          .pipe(
            switchMap(() => {
              return this.iotService.registerDevice(params.activationCode, true, true);
            })
          )
          .subscribe();
      }
    });
  }

  ngOnDestroy() {
    unsubscribe(this);
  }

  prepareRoute(): string {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  fetchURLs() {
    this.systemService.getURLs().subscribe(urls => {
      this.alcedoURL = urls.main;
    });
  }

  goToAvelon() {
    window.open(this.alcedoURL, '_blank', 'noreferrer');
  }

  signIn() {
    this.oidcSecurityService.authorize();
  }

  logOut() {
    UserSessionService.destroy();
    this.oidcSecurityService.logoff().subscribe();
  }

  clientChange($event: number): void {
    UserSessionService.setSelectedClientId($event);
    this.selectedClientId = $event;
    this.permissions.flushPermissions();
    this.authService.loadCurrentUser().subscribe();
    this.iotService.reloadDevices.next();
    this.router.navigate([''], {});
  }

  goToNoAccountInfo() {
    this.router.navigate(['no-account-info']);
  }
}
