import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormsModule, NgModel} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';
import {ActivatedRoute} from '@angular/router';
import {TranslocoModule} from '@jsverse/transloco';
import {Language} from 'api/entities';
import {Subscription} from 'rxjs/internal/Subscription';
import {filter} from 'rxjs/operators';
import {AccountType, initNewClient} from '../../../../../../alcedo/src/app/access-control/sign-up/sign-up.static';
import {UserLocaleService} from '../../../../../../alcedo/src/app/core/auth/user-locale.service';
import {unsubscribe} from '../../../../../../alcedo/src/app/shared/decorators/unsubscribe.decorator';
import {DeviceActivationEntity} from '../../../../../../alcedo/src/app/shared/entities/devices/device-activation.entity';
import {NewClient} from '../../../../../../alcedo/src/app/shared/entities/devices/device.interface';
import {UserLocales} from '../../../../../../alcedo/src/app/shared/properties/user/user-locales.enum';
import {slideInAnimation} from '../../../core/animation';
import {UserLocalesLabels} from '../../../core/user-locales-labels.constant';
import {UserTitleLabels} from '../../../core/user-title-labels.constant';
import {AppFormFieldComponent} from '../../shared/app-form-field/app-form-field.component';
import {AppInputErrorComponent} from '../../shared/app-form-field/app-input-error.component';
import {AppInputLabelComponent} from '../../shared/app-form-field/app-input-label.component';
import {AppInputDirective} from '../../shared/app-form-field/app-input.directive';
import {SignUpBusinessComponent} from '../sign-up-business/sign-up-business.component';
import {SignUpPrivateComponent} from '../sign-up-private/sign-up-private.component';

export type SignUpSteps = 1 | 2 | 3;

@Component({
  selector: 'app-sign-up-ask',
  templateUrl: './sign-up-ask.component.html',
  styleUrls: ['./sign-up-ask.component.scss'],
  standalone: true,
  animations: [slideInAnimation],
  imports: [
    AppFormFieldComponent,
    AppInputDirective,
    AppInputErrorComponent,
    AppInputLabelComponent,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatRadioButton,
    MatRadioGroup,
    SignUpBusinessComponent,
    SignUpPrivateComponent,
    TranslocoModule
  ]
})
export class SignUpAskComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('accountType') accountType: NgModel;
  @ViewChild('companyInput') companyInput: ElementRef;
  @ViewChild('firstNameInput') firstNameInput: ElementRef;
  step: SignUpSteps = 1;
  newClient: NewClient = initNewClient();
  hasPaidLicenses: boolean;
  UserLocalesLabels: Record<UserLocales, string>;
  userTitles: Record<string, string>;
  readonly AccountType = AccountType;
  private paidLicenses$: Subscription;
  private elementFocus$: Subscription;
  private userLocaleService$: Subscription;

  constructor(
    private deviceActivationEntity: DeviceActivationEntity,
    private userLocaleService: UserLocaleService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.newClient.activationCode = this.route.snapshot.paramMap.get('activationCode');
    this.paidLicenses$ = this.deviceActivationEntity.checkDevicePaidLicenses(this.newClient.activationCode).subscribe(hasPaidLicenses => {
      this.hasPaidLicenses = hasPaidLicenses;
      if (!hasPaidLicenses) {
        delete this.newClient.createClientRequest.billingDetails;
      }
    });

    this.UserLocalesLabels = UserLocalesLabels;
    this.userTitles = UserTitleLabels;
    if (this.newClient.createClientRequest.billingDetails) {
      this.userLocaleService$ = this.userLocaleService
        .getCountry()
        .pipe(filter(() => !!this.newClient.createClientRequest.billingDetails))
        .subscribe((country: string) => (this.newClient.createClientRequest.billingDetails.country = country));
    }

    this.newClient.createClientRequest.user.language = UserLocaleService.detectUserLocale(navigator.language) as Language;
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.companyInput.nativeElement.focus(), 200);
    this.elementFocus$ = this.accountType.valueChanges.subscribe(value => {
      setTimeout(() => (value === AccountType.COMPANY ? this.companyInput.nativeElement.focus() : this.firstNameInput.nativeElement.focus()), 200);
    });
    // used 200ms to make sure the elements are displayed and classes are set on accountType changes and smoother behavior
  }

  ngOnDestroy(): void {
    unsubscribe(this);
  }

  updateModels(): void {
    if (this.newClient.createClientRequest.billingDetails) {
      this.newClient.createClientRequest.billingDetails.firstName = this.newClient.firstName;
      this.newClient.createClientRequest.billingDetails.lastName = this.newClient.lastName;
    }
    this.newClient.createClientRequest.user.firstName = this.newClient.firstName;
    this.newClient.createClientRequest.user.lastName = this.newClient.lastName;
  }
}
