import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AutoLoginPartialRoutesGuard, OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable, of, switchMap} from 'rxjs';
import {UserSessionService} from '../../../../../alcedo/src/app/shared/entities/auth/user-session.service';
import {AuthService} from '../auth.service';

export function optionalLoginGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
  if (inject(AuthService).resolvedAuth) {
    return true;
  }

  const oidcSecurityService = inject(OidcSecurityService);
  const autoLogin = inject(AutoLoginPartialRoutesGuard);
  return oidcSecurityService.isAuthenticated().pipe(
    switchMap(isAuthenticated => {
      if (!isAuthenticated && UserSessionService.getSessionDummy() && !state.url.includes('?state=')) {
        return autoLogin.canActivate(route, state);
      } else {
        return of(true);
      }
    })
  );
}
